import React from 'react';
import api from 'brighteon/api';
import { BRIGHTEON_URL } from '../../../constants';

class BrighteonNewVideosContainer extends React.PureComponent {

  state = {
    videos: [],
  }

  componentDidMount() {
    api().get(`${BRIGHTEON_URL}/api-v3/channels/hrreport/videos`)
      .then(({ data }) => {
        this.setState({ videos: data.videos })
      })
      .catch(err => console.warn(err))
  }

  render() {
    const videos = this.state.videos;

    return (
      <div className='widget'>
        <span className='title'>
          New on Brighteon
        </span>
        <div className='content'>
          <div id='BrighteonTVEpisodes'>
            {
              videos.slice(0, 5).map((video, i) => {
                return (
                  <div className="Text">
                    <a href={`${BRIGHTEON_URL}/${video.id}`} target="_blank">
                      <div className="Photo">
                        <div className="Thumb">
                          <img src={video.thumbnail} /><span className="Duration">{video.duration}</span>
                        </div>
                      </div>
                      <div className="Headline">{video.name}</div>
                    </a>
                  </div>
                );
              })
            }
          </div>
          <div className='actions'>
            <a href={BRIGHTEON_URL} target='_blank' className='demo-button demo-button-a demo-button--block'>Watch Brighteon</a>
            <a href={`${BRIGHTEON_URL}/channels/hrreport`} target='_blank'>Previous Episodes</a>
          </div>
        </div>
      </div>
    );
  }

}

export default BrighteonNewVideosContainer;
