import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LinkFooter from './link_footer';
import { changeComposing } from 'brighteon/actions/compose';
import DonateBoxContainer from '../../../../brighteon/features/compose/containers/donate_box_container';
import BrighteonShopContainer from '../../../../brighteon/features/compose/containers/brighteon_shop_container';
import LatestNewsContainer from '../../../../brighteon/features/compose/containers/latest_news_container';
import BrighteonVideosContainer from '../../compose/containers/brighteon_videos_container';
import BrightUContainer from '../../../../brighteon/features/compose/containers/bright_u_container';
import WelcomeContainer from '../../../../brighteon/features/compose/containers/welcome_container';
import TrendsContainer from 'brighteon/features/getting_started/containers/trends_container';
import { showTrends, me } from 'brighteon/initial_state';
import AnnouncementsContainer from '../../getting_started/containers/announcements_container';
import Sticky from 'react-stickynode';

const mapStateToProps = state => ({
  hasAnnouncements: !state.getIn(['announcements', 'items']).isEmpty(),
});

export default @connect(mapStateToProps)
class ComposePanel extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    hasAnnouncements: PropTypes.bool,
  };

  onFocus = () => {
    this.props.dispatch(changeComposing(true));
  }

  render() {
    const { hasAnnouncements } = this.props;
    return (
      <div className='compose-panel' onFocus={this.onFocus}>
        <div className='compose-panel-inner'>
          <Sticky top={77} enabled>
            {!me && <WelcomeContainer />}
            <DonateBoxContainer />
            {hasAnnouncements && <AnnouncementsContainer />}
            <BrighteonShopContainer />
            <LatestNewsContainer />
            <BrighteonVideosContainer />
            <BrightUContainer />
            <div className='spacer' />
            {
              showTrends && (
                <TrendsContainer />
              )
            }
            <LinkFooter />
          </Sticky>
        </div>
      </div>
    );
  }

}
