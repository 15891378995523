import React from 'react';
import api from 'brighteon/api';
import { BRIGHTEON_URL, BRIGHTU_URL } from '../../../constants';

class BrightUContainer extends React.PureComponent {

    state = {
      brightu_items: [{
        url: '#',
        description: ''
      }],
    }
  
    componentDidMount() {
      api().get(`${BRIGHTEON_URL}/api-v3/home-page`)
        .then(({ data }) => {
          const brightuItems = data.result.filter(category => category.categoryName === 'brighteonUniversityItems')[0].items
          this.setState({ brightu_items: brightuItems })
        })
        .catch(err => console.warn(err))
    }

  render() {
    const firstItem = this.state.brightu_items[0]
    return (
      <div className='widget'>
        <span className='title'>
          Streaming Free on BrightU
        </span>
        <div className='content'>
          <div>
            <img className='w-100 radius-rounded-4px' src={firstItem.imageUrl} alt='brighteon-university' />
          </div>
          <div className='text'>
            <p>
              {firstItem.description}
            </p>
          </div>
          <div className='actions'>
            <a href={BRIGHTU_URL} target='_blank' className='demo-button demo-button-a demo-button--block'>Stream Now</a>
          </div>
        </div>
      </div>
    );
  }

}

export default BrightUContainer;
